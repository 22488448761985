// Copyright Secret Gifter App. 2024. All rights reserved.
// Use of this source code is governed by license that can be found in the LICENSE file.
import '../assets/css/style.css';
const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};
document.addEventListener('DOMContentLoaded', () => {
    const newsLetterEmail = document.getElementById('newsletter-email');
    const newsLetterSubmit = document.getElementById('newsletter-submit');
    if (newsLetterEmail) {
        newsLetterEmail.addEventListener('keyup', (_) => {
            newsLetterSubmit.disabled = !isValidEmail(newsLetterEmail.value.trim());
        });
        newsLetterEmail.addEventListener('', (_) => {
            newsLetterSubmit.disabled = !isValidEmail(newsLetterEmail.value.trim());
        });
        newsLetterSubmit.addEventListener('click', (event) => {
            event.preventDefault();
            const url = '/api/newsletter';
            fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    email: newsLetterEmail.value.trim(),
                })
            })
                .then(res => res.json())
                .then(value => {
                alert('Thank you!');
            });
        });
    }
    const contactEmail = document.getElementById('contactEmail');
    const contactMessage = document.getElementById('contactMessage');
    const contactSubmit = document.getElementById('contactSubmit');
    if (contactEmail) {
        contactEmail.addEventListener('keyup', (_) => {
            contactSubmit.disabled = !isValidEmail(contactEmail.value.trim());
        });
        contactSubmit.addEventListener('click', (event) => {
            event.preventDefault();
            const url = '/api/contact';
            fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    email: contactEmail.value.trim(),
                    message: contactMessage.value.trim(),
                })
            })
                .then(res => res.json())
                .then(value => {
                alert('Thank you!');
            });
        });
    }
});
